.loader-modal {
  position: fixed;
  z-index: 999;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: Black;
  filter: alpha(opacity=60);
  opacity: 0.6;
  -moz-opacity: 0.8;
}
.center-loader {
  z-index: 1000;
  margin: 300px auto;
  padding: 10px;
  width: 130px;
  border-radius: 10px;
  filter: alpha(opacity=100);
  opacity: 1;
  -moz-opacity: 1;
}
.center-loader img {
  height: 100px;
  width: 100px;
}
