
/*! #######################################################################

	MeanMenu 2.0.7
	--------

	To be used with jquery.meanmenu.js by Chris Wharton (http://www.meanthemes.com/plugins/meanmenu/)

####################################################################### */

/* hide the link until viewport size is reached */
a.meanmenu-reveal {
	display: none;
}
/* when under viewport size, .mean-container is added to body */
.mean-container .mean-bar {
	float: left;
	width: 100%;
	position: relative;
	background: #0c1923;
	padding: 4px 0;
	min-height: 42px;
	z-index: 999999;
}
.mean-container a.meanmenu-reveal {
  color: #fff;
  cursor: pointer;
  display: block;
  font-family: Arial,Helvetica,sans-serif;
  font-weight: 700;
  height: 22px;
  line-height: 22px;
  padding: 13px 13px 11px;
  position: absolute;
  right: 0;
  text-decoration: none;
  top: 0;
  width: 22px;
}
.mean-container a.meanmenu-reveal span {
	display: block;
	background: #fff;
	height: 3px;
	margin-top: 3px;
}
.mean-container .mean-nav {
    background: #ffffff none repeat scroll 0 0;
    float: left;
    margin-top: 44px;
    width: 100%;
}
.mean-container .mean-nav ul {
	padding: 0;
	margin: 0;
	width: 100%;
	list-style-type: none;
}
.mean-container .mean-nav ul li {
	position: relative;
	float: left;
	width: 100%;
}
.mean-container .mean-nav ul li a {
  border-top: 1px solid rgba(0, 0, 0, 0.5);
  border-left: 1px solid rgba(0, 0, 0, 0.5);
  color: #000000;
  display: block;
  float: left;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  padding: 1.2em 5%;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
  width: 90%;
    line-height: 17px;
}
.mean-last {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}
.mean-container .mean-nav ul li a:hover {color: #EC1C23;}
.mean-container .mean-nav ul li li a {
	width: 80%;
	padding: 1em 10%;
	border-top: 1px solid #f1f1f1;
	border-top: 1px solid rgba(0, 0, 0, 0.5);
	opacity: 0.8;
	filter: alpha(opacity=75);
	text-shadow: none !important;
	visibility: visible;
}
.mean-container .mean-nav ul li.mean-last a {
	border-bottom: none;
	margin-bottom: 0;
}
.mean-container .mean-nav ul li li li a {
	width: 70%;
	padding: 1em 15%;
}
.mean-container .mean-nav ul li li li li a {
	width: 60%;
	padding: 1em 20%;
}
.mean-container .mean-nav ul li li li li li a {
	width: 50%;
	padding: 1em 25%;
}
.mean-container .mean-nav ul li a:hover {
	background: #252525;
	background: rgba(255,255,255,0.1);
}
.mean-container .mean-nav ul li a.mean-expand {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background: rgba(255, 255, 255, 0.1) none repeat scroll 0 0;
  border-color: currentcolor currentcolor rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.7);
  border-image: none;
  border-style: none none solid solid;
  border-width: medium medium 1px 1px;
  font-weight: 700;
  height: 22px;
  line-height: 21px;
  margin-top: 1px;
  padding: 11.5px 12px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 22px;
  z-index: 2;
}
.mean-container .mean-nav ul li a.mean-expand:hover {
    background: rgba(0, 0, 0, 0.9) none repeat scroll 0 0;
    color: #ffffff;
}
.mean-container .mean-push {
	float: left;
	width: 100%;
	padding: 0;
	margin: 0;
	clear: both;
}
.mean-nav .wrapper {
	width: 100%;
	padding: 0;
	margin: 0;
}
/* Fix for box sizing on Foundation Framework etc. */
.mean-container .mean-bar, .mean-container .mean-bar * {
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
}
.mean-remove {
	display: none !important;
}
.mean-nav span {
  display: none;
}